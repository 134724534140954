<template>
  <div id="box">
    <div class="box_left" v-if="!ismobile">
        <div class="logoimg">
            <img src="../assets/img/dl6.png" alt="">
        </div>
        <!-- 登录框 -->
        <div class="login_box" v-if="!isLogin">
          <img @click="goblack" class="login_gbi" src="../assets/img/gbi01.png" alt="">
          <div class="login_tit">
            <img src="../assets/img/dl3.png" alt="">
          </div>
          <div class="login_form">
              <div class="acc">
                <img src="../assets/img/manniu02.png" alt="">
                <el-input v-model="loginForm.account" class="acc_input" placeholder="请输入账号"></el-input>
              </div>
              <div class="password">
                <img src="../assets/img/manniu01.png" alt="">
                <el-input v-model="loginForm.password" class="acc_input" :type="passwordType" placeholder="请输入密码"></el-input>
                <img class="passlike" src="../assets/img/manniu06.png" alt="" @click="showPwd">
              </div>
              <div class="jzzz">
                  <el-checkbox v-model="checked">记住账号</el-checkbox>
              </div>
              <div class="login_but" @click="submitForm()">
                 点击登录
              </div>
          </div>
        </div>
        <!-- 充值框 -->
        <div class="cz_pop" v-else>
          <div class="cz_top">
             <div class="cz_back" @click="goblack">
               <img src="../assets/img/right.png" alt="">
               <span>返回</span>
             </div>
             <div></div>
          </div>
          <div class="cz_form">
            <div class="cz_je">
                <div class="je_text">充值金额</div>
                <div class="je_input">
                  <el-input type="number" v-model="params.amount" placeholder="请输入充值金额"></el-input>
                  <!-- <input type="number" placeholder="请输入充值金额"> -->
                  <span>RMB</span>
                </div>
            </div>
            <div class="cz_type">
              <div class="type_text">充值方式</div>
              <div class="type_list">
                <div :class="{'cz_wx': true, 'cz_nav': true, 'xz_nav': item.isflag}" v-for="(item, index) in typeList" :key="index" @click="changeType(index)">
                  <img :src="item.img" alt="">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
            <div class="cz_but" @click="doPay">
              充值
            </div>
          </div>
        </div>
    </div>
    <div class="mod_login" v-else>
      <!-- 充值框 -->
      <div class="mod_cz" v-if="isLogin">
        <div class="mod__close">
          <div></div>
          <img @click="goblack" src="../assets/img/sj09.png" alt="">
        </div>
        <div class="mod_title">充值金额</div>
        <div class="mod_input">
          <el-input type="number" v-model="params.amount" placeholder="请输入充值金额"></el-input>
        </div>
        <div class="mod_title">充值方式</div>
        <div class="mod_list">
          <div class="mod_nav"  v-for="(item, index) in typeList" :key="index" @click="changeType(index)">
            <div class="mod_nav_left">
              <img :src="item.img" alt="">
              <span>{{item.name}}</span>
            </div>
            <div :class="{'mod_nav_right': true, 'mod_xz': item.isflag} "></div>
          </div>
        </div>
        <div class="mod_but" @click="doPay">
          充值
        </div>
      </div>
      <!-- 登录框 -->
      <div class="mod_dl" v-else>
        <div class="mod__close">
          <div></div>
          <img @click="goblack" src="../assets/img/sj09.png" alt="">
        </div>
        <div class="login_tit">
            <img src="../assets/img/sj05.png" alt="">
          </div>
          <div class="login_form">
              <div class="acc">
                <img src="../assets/img/sj06.png" alt="">
                <el-input v-model="loginForm.account" class="acc_input" placeholder="请输入账号"></el-input>
              </div>
              <div class="password">
                <img src="../assets/img/sj07.png" alt="">
                <el-input v-model="loginForm.password" class="acc_input" :type="passwordType" placeholder="请输入密码"></el-input>
                <img class="passlike" src="../assets/img/manniu06.png" alt="" @click="showPwd">
              </div>
              <div class="jzzz">
                  <el-checkbox v-model="checked">记住账号</el-checkbox>
              </div>
              <div class="login_but" @click="submitForm()">
                 点击登录
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth' // 判断是否登录
import { doLogin } from '@/api/login' // 登录
import { getRedirect, setNeedValid, removeToken, removeTimeTag } from '@/utils/auth' // 验权
import { doSendSms, doValid } from '@/api/auth'
import { downloadAPk } from '@/api/cz'
import { createRecharge, payRecharge, stateRecharge, payConfig } from '@/api/cz' // 创建订单 支付 支付状态 支付配置
// import store from ' @/store'
export default {
  name: 'Index',
  data() {
    return {
      isLogin: true,
      checked: false,
      ismobile: false,
      loginForm: {
        account: '',
        password: '',
        type: 1
      },
      passwordType: 'password',
      typeList: [
      ],
      params: {
        amount: 0,
        payWay: 2
      },
      payParams: {
            payWay: 2,
            id: 0,
            bankId: '',
            type: 1 
      }
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.detectDevice()
    // 判断是否登录 
    if (getToken()) {
      this.isLogin = true
      if (sessionStorage.getItem('id')) {
        // 查询支付状态
        this.stateRecharge()
      }
      this.getPayConfig()
    } else  {
      this.isLogin = false
      if (localStorage.getItem('username')) {
        this.checked = true
        this.loginForm.account = localStorage.getItem('username')
      }
      if (localStorage.getItem('password')) {
        this.checked = true
        this.loginForm.password = localStorage.getItem('password')
      }
    }
  },
  beforeDestroy() {
  },
  methods: {
      detectDevice () {
          // 获取userAgent字符串
          var userAgent = navigator.userAgent || navigator.vendor || window.opera
      
          // 使用正则表达式来检查userAgent字符串中是否包含某些关键字
          // 这些关键字通常与移动设备相关
          var mobile = /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(
              userAgent.toLowerCase()
          )
      
          if (mobile) {
              // 如果userAgent包含上述关键字之一，则认为是在移动设备上
              console.log('访问设备是移动设备')
              this.ismobile = true
          } else {
              // 否则，认为是在PC（桌面设备）上
              console.log('访问设备是PC（桌面设备）')
              this.ismobile = false
          }
      },
    // 返回到首页
    goblack() {
      this.$router.push('./')
    },
    // 获取支付配置
    getPayConfig() {
      var that = this
      payConfig().then(res => {
        res.data.forEach((item, index) => {
            var obj = {
                name: item.name,
                img: require('../assets/img/wx.png'),
                type: item.type,
                payWay: item.payWay,
                isflag: index == 0?true:false
            }
            if (that.ismobile) {
              if (item.payWay == 2) {
              obj.img = require('../assets/img/sj10.png')
              } else if (item.payWay == 3) {
                obj.img = require('../assets/img/sj11.png')
              } else if (item.payWay == 1) {
                obj.img = require('../assets/img/sj12.png')
              }
            } else {
              if (item.payWay == 2) {
                obj.img = require('../assets/img/wx.png')
              } else if (item.payWay == 3) {
                obj.img = require('../assets/img/zfb.png')
              } else if (item.payWay == 1) {
                obj.img = require('../assets/img/yhk.png')
              }
            }
            that.typeList.push(obj)
            if (index == 0) {
              that.payParams.payWay = item.payWay
              that.payParams.type = item.type
              that.params.payWay = item.payWay
            }
        });
      })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
    },
    // 登录
    submitForm() {
      var that = this
      if (!that.loginForm.account) {
        that.$layer.quicktips('请输入账号')
        return
      }
      if (!that.loginForm.password) {
        that.$layer.quicktips('请输入密码')
        return
      }
      that.$store.dispatch('Login', that.loginForm).then((response) => {
        // 登录成功
        if (that.checked) {
          localStorage.setItem('username', that.loginForm.account)
          localStorage.setItem('password', that.loginForm.password)
        } else {
              localStorage.removeItem('username')
              localStorage.removeItem('password')
        }
        // 用户跳转
        // const newpath = getRedirect()
        // that.$router.push({ path: newpath || '/' })
        that.isLogin = true
        that.getPayConfig()
      })
    },
    changeType(index) {
      var that = this
      that.payParams.payWay = that.typeList[index].payWay
      that.payParams.type = that.typeList[index].type
      that.params.payWay = that.typeList[index].payWay
      that.typeList.forEach((item, i) => {
        if(i == index) {
          item.isflag = true
        } else {
          item.isflag = false
        }
      })
    },
    // 充值
    doPay() {
      var that = this
      if (that.params.amount == 0) {
        that.$layer.quicktips('请输入充值金额')
        return
      }
      // 创建订单
      createRecharge(that.params).then(res => {
        if (res.state == 200) {
          // 支付
          that.payParams.id = res.data.id
          sessionStorage.setItem('id', res.data.id)
          payRecharge(that.payParams).then(res => {
            if (res.state == 200) {
              // window.open(res.data.url)
              window.location.href = res.data.url
            }
          })
        }
      })
    },
    // 支付状态
    stateRecharge() {
      var that = this
      stateRecharge(sessionStorage.getItem('id')).then(res => {
          sessionStorage.removeItem('id')
          if (res.state == 200) {
            if (res.data == 2) {
              that.$layer.quicktips('充值成功')
            } else {
              that.$layer.quicktips('充值失败')
            }
          }
      })
    }
  }
}
</script>

<style scoped lang="less">
#box{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000000;
}
.box_left{
   width: 100vw;
   height: 100vh;
   background: url('../assets/img/dl7.png') no-repeat;
   background-size: 100% 100%;
   
}
.box_right{
  height: 100vh;
}
.box_right img{
  height: 100vh;
}
.logoimg{
  margin: 30px 0px 0px 34px;
}
.logoimg img{
  width: 130px;
  height: 34px;
}
.login_box{
  width: 560px;
  margin: auto;
  margin-top: 126px;
  padding: 60px 80px;
  background: #141718;
  position: relative;
}
.login_gbi{
  width: 26px;
  position: absolute;
  right: 22px;
  top: 27px;
  cursor: pointer;
}
.login_tit{
}
.login_tit img{
  width: 288px;
}
.login_form{
  margin-top: 30px;
}
.login_form .acc,.login_form .password{
  width: 400px;
  height: 50px;
  background: #2A2C2E;
  border-radius: 5px 5px 5px 5px;
  line-height: 50px;
  padding: 0px 14px;
  display: flex;
  align-items: center;
}
.login_form .acc img, .login_form .password img{
  width: 18px;
}
.acc_input{
  height: 50px !important;
}
/deep/.acc_input .el-input__inner{
    height: 50px;
    background: #2A2C2E !important;
    border: none;
    color: #fff;
    text-align: left;
    height: 50px !important;
}
.password{
  margin-top: 16px;
  position: relative;
}
.passlike{
  position: absolute;
  right: 17px;
  top: 20px;
  width: 16px;
  cursor: pointer;
}
.jzzz{
  margin-top: 15px;
  /deep/.el-checkbox__label{
    color: #fff !important;
  }
  /deep/.is-checked>.el-checkbox__inner {
    background: #FFDD99 !important;
  }
}
.login_but{
  width: 400px;
  height: 52px;
  background: #FFDD99;
  border-radius: 5px 5px 5px 5px;
  margin-top: 57px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: #04080B;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
}
.login_cop{
  margin-top: 128px;
  display: flex;
  padding: 30px 80px;
  img{
    width: 63px;
    margin: auto;
    cursor: pointer;
  }
}

.cz_pop{
  width: 625px;
  // height: 459px;
  background: #1A1B1B;
  padding: 26px 27px 48px 36px;
  position: fixed;
  top: calc(50vh - 229px);
  left: calc(50vw - 312px);
  .cz_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cz_back{
      display: flex;
      align-items: center;
      cursor: pointer;
      img{
        width: 8px;
        vertical-align: middle;
      }
      span{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #FFDD99;
        margin-left: 11px;
      }
    }
  }
  .cz_form{
    width: 90%;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  margin: auto;
  padding-top: 51px;
  .cz_je{
    .je_text{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #FFFFFF;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
    .je_input{
      width: 462px;
      height: 68px;
      border-radius: 0px 0px 0px 0px;
      border: 1px solid #353535;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 16px;
      @media screen and (max-width: 1024px) {
        width: 90%;
        height: 48px;
        font-size: 16px;
      }
      .el-input{
        font-family: DIN-Bold, DIN-Bold;
        font-weight: 500;
        font-size: 24px;
        color: #353535;
        outline: none;
        border: none;
      }
      /deep/.el-input__inner{
        color: #FFFFFF!important;
        background: #1A1B1B !important;
        text-align: left;
        font-size: 24px!important;
        @media screen and (max-width: 1024px) {
          font-size: 16px !important;
        }
      }
      span{
        font-family: DIN-Bold, DIN-Bold;
        font-weight: 500;
        font-size: 24px;
        color: #999999;
        @media screen and (max-width: 1024px) {
          font-size: 18px;
        }
      }
    }
  }
  .cz_type{
    margin-top: 32px;
    .type_text{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #FFFFFF;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
    .type_list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      
      .cz_nav{
        margin-top: 10px;
        width: 144px;
        height: 56px;
        border-radius: 0px 0px 0px 0px;
        border: 1px solid #353535;
        margin-right: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        @media screen and (max-width: 1024px) {
          width: 120px;
          padding: 0px 10px;
          height: 50px;
          justify-content: center;
        }
        img{
          width: 24px;
          margin-left: 26px;
          @media screen and (max-width: 1024px) {
            margin-left: 0px;
          }
        }
        span{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #DFDFDF;
          margin-left: 7px;
          @media screen and (max-width: 1024px) {
           font-size: 14px;
          }
        }
      }
    }
  }
  .cz_but{
    cursor: pointer;
    width: 494px;
    height: 68px;
    background: #FFDD99;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #353535;
    margin-top: 44px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #04080B;
    text-align: center;
    line-height: 68px;
    @media screen and (max-width: 1024px) {
      width: 90%;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }
  }
}
.xz_nav{
  border: 1px solid #FFDD99 !important;
  position: relative;
}
.xz_nav::before{
    content: '';
    width: 22px;
    height: 22px;
    background: #FFDD99;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
    top: 0px;
    right: 0px;
    background: url('../assets/img/gou.png') no-repeat;
    background-size: 100% 100%;
}
}



.mod_login{
  width: 100vw;
  height: 100vh;
  background: url('../assets/img/sj04.png') no-repeat;
  background-size: 100% 100%;
  .mod_dl{
    width: 305px;
    // height: 463px;
    background: #2A2B2C;
    padding: 23px 21px;
    position: fixed;
    top: calc(50vh - 208px - 23px);
    left: calc(50vw - 131px - 21px);
    z-index: 99;
    border-radius: 6px;
    .mod__close{
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        width: 14px;
      }
    }
    .login_tit{
      margin-top: 14px;
      img{
        width: 132px;
        margin: auto;
      }
    }
    .acc, .password{
      width: 264px;
      height: 50px;
      background: #5F5F60;
      border-radius: 5px 5px 5px 5px;
      /deep/.el-input__inner{
        width: 229px !important;
        height: 50px!important;
        background: #5F5F60!important;
        font-family: DIN-Bold, DIN-Bold;
        font-weight: 500;
        font-size: 16px;
        
        color: #D0D0D0!important;
        text-align: left;
      }
    }
    .login_but{
      margin-top: 30px;
      width: 264px;
      line-height: 52px;
      background: #FFDD99;
      border-radius: 5px 5px 5px 5px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #04080B;
      text-align: center;
    }
  }
  .mod_cz{
    width: 305px;
    // height: 463px;
    background: #2A2B2C;
    padding: 23px 21px;
    position: fixed;
    top: calc(50vh - 208px - 23px);
    left: calc(50vw - 131px - 21px);
    z-index: 99;
    border-radius: 6px;
    .mod__close{
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        width: 14px;
      }
    }
    .mod_title{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      margin-top: 23px;
      line-height: 34px;
    }
    .mod_input{
      width: 259px;
      height: 50px;
      background: #5F5F60;
      border-radius: 6px 6px 6px 6px;
      /deep/.el-input__inner{
        width: 259px !important;
        height: 50px!important;
        background: #5F5F60!important;
        font-family: DIN-Bold, DIN-Bold;
        font-weight: 500;
        font-size: 20px;
        color: #D0D0D0!important;
        text-align: left;
      }
    }
    .mod_list{
      .mod_nav{
        margin-bottom: 8px;
        width: 259px;
        height: 50px;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid rgba(255,255,255,0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 12px;
        .mod_nav_left{
          display: flex;
          align-items: center;
          img{
            width: 18px;
            vertical-align: middle;
          }
          span{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #DFDFDF;
            margin-left: 5px;
          }
        }
        .mod_nav_right{
          width: 15px;
          height: 15px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 50%;
        }
        .mod_xz{
          background: url('../assets/img/sj13.png') no-repeat;
          background-size: 100% 100%;
          border:none;
        }
      }
    }
    .mod_but{
      width: 264px;
      line-height: 52px;
      background: #FFDD99;
      border-radius: 5px 5px 5px 5px;
      margin-top: 24px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #04080B;
      text-align: center;
    }
  }
}

</style>
